import './App.css';
import AppRouter from './Routes/AppRouter';

function App() {
  return (
    <div>
   <AppRouter />
    </div>
  );
}

export default App;
